import React, { useContext, useRef, useState } from "react";
import { StyleClass } from "primereact/styleclass";
import { Button } from "primereact/button";
import { Ripple } from "primereact/ripple";
import { Divider } from "primereact/divider";
import { LayoutContext } from "../../layout/context/layoutcontext";
import { NodeRef } from "../../types/index";
import { classNames } from "primereact/utils";
import { Link, useNavigate } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { InputNumber, InputNumberValueChangeEvent } from "primereact/inputnumber";
import { useFormik } from "formik";
import { Card } from "primereact/card";
import aosbLogo from "../../assets/images/aosb_ces_logo.jpg";

export interface Form1 {
  value1: number;
  value2: number;
  value3: number;
  value4: number;
}

const KarbonAyakIzi = () => {
  const [value1, setValue1] = useState<number>(72723);
  const [value2, setValue2] = useState<number>(58151);
  const [value3, setValue3] = useState<number>(20);
  const [value4, setValue4] = useState<number>(1500);

  const formikForm1 = useFormik<Form1>({
    initialValues: {
      value1: 0,
      value2: 0,
      value3: 0,
      value4: 0
    },
    validate: (values) => {
      const errors: any = {};
      return errors;
    },
    onSubmit: async (values) => {}
  });

  return (
    <div className="card">
      <div className="w-full surface-card">
        <div className="text-center mb-5">
          <div className="text-900 text-3xl font-medium mb-3">Karbon Ayak İzi Anketi</div>
        </div>
        <div className="iframe-container">
          <iframe
            src="https://strategyandsystem.com/cor/index.html"
            height="512px"
            width="100%"
            loading="lazy"
          ></iframe>
        </div>
      </div>

      <div className="mb-3 w-full flex justify-content-center">
        <Divider align="center" className="w-9" />
      </div>
      <div className="w-full surface-card">
        <div className="text-center mb-5">
          <div className="text-900 text-3xl font-medium mb-3">Karbon Ayak İzi Anketi</div>
        </div>
        <div className="flex flex-column align-items-center justify-content-center">
          <div className="grid w-8 p-2">
            <div className="col-12 md:col-8 flex align-items-center">
              <label htmlFor="newEmail" className="text-900 text-xl font-medium mb-2">
                OSB bünyesinde yer alan araç sayısı:
              </label>
            </div>
            <div className="col-12 md:col-4">
              <div className="p-inputgroup flex-1">
                <InputNumber
                  inputId="integeronly"
                  value={value1}
                  onChange={(e) => formikForm1.setFieldValue("value1", e.value)}
                  showButtons
                />
              </div>
            </div>
            <div className="col-12 md:col-8 flex align-items-center">
              <label htmlFor="newEmail" className="text-900 text-xl font-medium mb-2">
                OSB bünyesinde alınan araç sayısı:
              </label>
            </div>
            <div className="col-12 md:col-4">
              <div className="p-inputgroup flex-1">
                <InputNumber
                  inputId="integeronly"
                  value={value2}
                  onChange={(e) => formikForm1.setFieldValue("value2", e.value)}
                  showButtons
                />
              </div>
            </div>
            <div className="col-12 md:col-8 flex align-items-center">
              <label htmlFor="newEmail" className="text-900 text-xl font-medium mb-2">
                OSB bünyesindeki araçların faal ömrü:
              </label>
            </div>
            <div className="col-12 md:col-4">
              <div className="p-inputgroup flex-1">
                <InputNumber
                  inputId="integeronly"
                  value={value3}
                  onChange={(e) => formikForm1.setFieldValue("value3", e.value)}
                  showButtons
                />
              </div>
            </div>
            <div className="col-12 md:col-8 flex align-items-center">
              <label htmlFor="newEmail" className="text-900 text-xl font-medium mb-2">
                OSB bünyesinde yıllık pert miktarı:
              </label>
            </div>
            <div className="col-12 md:col-4">
              <div className="p-inputgroup flex-1">
                <InputNumber
                  inputId="integeronly"
                  value={value4}
                  onChange={(e) => formikForm1.setFieldValue("value4", e.value)}
                  showButtons
                />
              </div>
            </div>
            <div className="col-12 mt-5 ">
              <div className="flex justify-content-end">
                <Button label="Gönder" type="button" className="p-3 text-xl"></Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mb-3 w-full flex justify-content-center">
        <Divider align="center" className="w-9" />
      </div>

      <div className="w-full surface-card px-5 sm:px-8" style={{ borderRadius: "53px" }}>
        <div className="text-center mb-5">
          <div className="text-900 text-3xl font-medium mb-3">Protokol Yapılan Firmalar</div>
        </div>
        <div className="flex flex-column align-items-center justify-content-center ">
          <div className="grid grid-nogutter justify-content-center w-full p-2">
            <Card
              header={
                <div className="flex flex-column align-items-center justify-content-center">
                  <span className="mb-2 font-bold text-xl text-black-alpha-90">ASO 2-3 OSB</span>
                  <img className="w-12rem h-12rem mt-2" alt="Card" src="https://www.asobcev.com.tr/images/logo.png" />
                </div>
              }
              className="md:w-15rem h-15rem hover:shadow-3 cursor-pointer transition-all m-3"
              onClick={() => (window.location.href = "https://www.asobcev.com.tr/")}
            ></Card>
            <Card
              header={
                <div className="flex flex-column align-items-center justify-content-center">
                  <span className="mb-2 font-bold text-xl text-black-alpha-90">ADANA OSB</span>
                  <img className="w-12rem h-8rem mt-2" alt="Card" src={aosbLogo} />
                </div>
              }
              className="md:w-15rem h-15rem hover:shadow-3 cursor-pointer transition-all m-3"
              onClick={() => (window.location.href = "http://www.aosb-co2.com/")}
            ></Card>
            <Card
              header={
                <div className="flex flex-column align-items-center justify-content-center">
                  <span className="mb-2 font-bold text-xl text-black-alpha-90">TOBB MEYBEM</span>
                  <img
                    className="w-12rem h-12rem mt-2"
                    alt="Card"
                    src="https://meybem.org.tr/wp-content/uploads/2023/11/meybem-logo.svg"
                  />
                </div>
              }
              className="md:w-15rem h-15rem hover:shadow-3 cursor-pointer transition-all m-3"
              onClick={() => (window.location.href = "https://meybem.org.tr/kurumsal-karbon-ayak-izi-egitimi-ankara/")}
            ></Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KarbonAyakIzi;
