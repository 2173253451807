import React, { useContext, useEffect, useRef, useState } from "react";
import { StyleClass } from "primereact/styleclass";
import { Button } from "primereact/button";
import { Ripple } from "primereact/ripple";
import { LayoutContext } from "../../layout/context/layoutcontext";
import { NodeRef } from "../../types/index";
import { classNames } from "primereact/utils";
import { Link, useNavigate } from "react-router-dom";
import { CarouselResponsiveOption, Carousel } from "primereact/carousel";
import { Tag } from "primereact/tag";
import { Card } from "primereact/card";
import '../../assets/styles/hover.scss';

interface Product {
  id: string;
  code: string;
  name: string;
  description: string;
  image: string;
  price: number;
  category: string;
  quantity: number;
  inventoryStatus: string;
  rating: number;
}

const LandingPage = () => {
  const [isHidden, setIsHidden] = useState(false);
  const { layoutConfig } = useContext(LayoutContext);
  const menuRef = useRef<HTMLElement | null>(null);
  const navigate = useNavigate();

  const toggleMenuItemClick = () => {
    setIsHidden((prevState) => !prevState);
  };

  const crouselItems: any = [
    {
      name: "Item 1",
      description: "Description for Item 1",
      image: "https://fastly.picsum.photos/id/237/200/300.jpg?hmac=TmmQSbShHz9CdQm0NkEjx1Dyh_Y984R9LpNrpvH2D_U"
    },
    {
      name: "Item 2",
      description: "Description for Item 2",
      image: "/images/logo.png"
    },
    {
      name: "Item 3",
      description: "Description for Item 3",
      image: "/images/logo.png"
    },
    {
      name: "Item 4",
      description: "Description for Item 4",
      image: "/images/logo.png"
    },
    {
      name: "Item 5",
      description: "Description for Item 5",
      image: "/images/logo.png"
    },
    {
      name: "Item 6",
      description: "Description for Item 3",
      image: "/images/logo.png"
    },
    {
      name: "Item 7",
      description: "Description for Item 4",
      image: "/images/logo.png"
    },
    {
      name: "Item 8",
      description: "Description for Item 5",
      image: "/images/logo.png"
    }
  ];

  const productTemplate = (product: Product) => {
    return (
      <div className="border-1 surface-border border-round m-2 text-center py-5 px-3">
        <div className="mb-3">
          <img src="/images/custom.jpg" alt={product.name} className="w-6 shadow-2" />
        </div>
        <div>
          <h4 className="mb-1 text-white">{product.name}</h4>
          <p className="text-white">{product.description}</p>
        </div>
      </div>
    );
  };

  return (
    <div className="surface-0 flex justify-content-center">
      <div id="home" className="landing-wrapper overflow-hidden">
        <div
          className="py-4 px-4 mx-0  flex align-items-center justify-content-between relative lg:static"
          style={{ backgroundColor: "#114d6b" }}
        >
          <Link to="/" className="flex align-items-center">
            <img src="/images/logoosbuk.png" alt="OSBÜK" height="50" className="mr-3 lg:mr-3" />
            <span className="text-900 font-medium text-2xl line-height-3 mr-2 w-12rem text-white">OSBÜK</span>
          </Link>
          <StyleClass
            nodeRef={menuRef as NodeRef}
            selector="@next"
            enterClassName="hidden"
            leaveToClassName="hidden"
            hideOnOutsideClick
          >
            <i ref={menuRef} className="pi pi-bars text-4xl cursor-pointer block lg:hidden text-700"></i>
          </StyleClass>
          <div
            className={classNames(
              "align-items-center  flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full left-0 px-6 lg:px-0 z-2",
              { hidden: isHidden }
            )}
            style={{ top: "100%" }}
          >
            <ul className="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row cursor-pointer">
              <li>
                <a
                  href="#home"
                  onClick={toggleMenuItemClick}
                  className="p-ripple flex m-0 md:ml-2 xl:ml-4 px-0 py-2 text-900 font-medium line-height-3 text-white"
                >
                  <span>Ana Sayfa</span>
                  <Ripple />
                </a>
              </li>
              <li>
                <a
                  href="#features"
                  onClick={toggleMenuItemClick}
                  className="p-ripple flex m-0 md:ml-2 xl:ml-4 px-0 py-3 text-900 font-medium line-height-3 text-white"
                >
                  <span>Özellikler</span>
                  <Ripple />
                </a>
              </li>
              <li>
                <a
                  href="#features"
                  onClick={toggleMenuItemClick}
                  className="p-ripple flex m-0 md:ml-2 xl:ml-4 px-0 py-3 text-900 font-medium line-height-3 text-white"
                >
                  <span>Mevzuatlar</span>
                  <Ripple />
                </a>
              </li>
            </ul>
            <div className="flex justify-content-between lg:block border-top-1 lg:border-top-none surface-border py-3 lg:py-0 mt-3 lg:mt-0">
              <Button
                label="Giriş Yap"
                text
                rounded
                className="border-none font-light line-height-2 text-blue-500 xl:mr-2"
                onClick={() => {
                  navigate("/login");
                }}
              ></Button>
              <Button
                label="Kayıt Ol"
                rounded
                className="border-none font-light line-height-2 bg-blue-500 text-white"
                onClick={() => {
                  navigate("/register");
                }}
              ></Button>
            </div>
          </div>
        </div>

        {/* <div id="features" className="py-4 px-4 lg:px-8 mt-5 mx-0 lg:mx-8">
          <div className="grid justify-content-center">
            <div className="col-12 lg:col-6 p-0 p-1 mt-4 lg:mt-0">
              <div
                className="h-8rem"
                onClick={() => navigate("/app/karbonayakizi", { replace: true })}
                style={{
                  padding: "2px",
                  borderRadius: "10px",
                  background:
                    "linear-gradient(90deg, rgba(253, 228, 165, 0.2), rgba(187, 199, 205, 0.2)), linear-gradient(180deg, rgba(253, 228, 165, 0.2), rgba(187, 199, 205, 0.2))"
                }}
              >
                <div className="p-3 surface-card h-full flex align-items-center" style={{ borderRadius: "8px" }}>
                  <div className="flex align-items-center">
                    <div
                      className="flex align-items-center justify-content-center bg-yellow-200 mr-3"
                      style={{
                        width: "7rem",
                        height: "5rem",
                        borderRadius: "50px"
                      }}
                    >
                      <i className="pi pi-fw pi-users text-2xl text-yellow-700"></i>
                    </div>
                    <div>
                      <h5 className="mb-1 text-900 text-lg">Raporla</h5>
                      <span className="text-600 text-sm">
                        Bireysel Sorgu ile belirli bir dosya hakkında hızlı ve etkili bir şekilde detaylı bilgi
                        sorgulaması yapabilirsiniz.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 lg:col-6 p-0 p-1 mt-4 lg:mt-0">
              <div
                className="h-8rem"
                style={{
                  padding: "2px",
                  borderRadius: "10px",
                  background:
                    "linear-gradient(90deg, rgba(253, 228, 165, 0.2), rgba(187, 199, 205, 0.2)), linear-gradient(180deg, rgba(253, 228, 165, 0.2), rgba(187, 199, 205, 0.2))"
                }}
              >
                <div className="p-3 surface-card h-full flex align-items-center" style={{ borderRadius: "8px" }}>
                  <div className="flex align-items-center">
                    <div
                      className="flex align-items-center justify-content-center bg-yellow-200 mr-3"
                      style={{
                        width: "7rem",
                        height: "5rem",
                        borderRadius: "50px"
                      }}
                    >
                      <i className="pi pi-fw pi-users text-2xl text-yellow-700"></i>
                    </div>
                    <div>
                      <h5 className="mb-1 text-900 text-lg">Raporlama</h5>
                      <span className="text-600 text-sm">
                        Bireysel Sorgu ile belirli bir dosya hakkında hızlı ve etkili bir şekilde detaylı bilgi
                        sorgulaması yapabilirsiniz.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 lg:col-6 p-0 p-1 mt-4 lg:mt-0">
              <div
                className="h-8rem"
                style={{
                  padding: "2px",
                  borderRadius: "10px",
                  background:
                    "linear-gradient(90deg, rgba(253, 228, 165, 0.2), rgba(187, 199, 205, 0.2)), linear-gradient(180deg, rgba(253, 228, 165, 0.2), rgba(187, 199, 205, 0.2))"
                }}
              >
                <div className="p-3 surface-card h-full flex align-items-center" style={{ borderRadius: "8px" }}>
                  <div className="flex align-items-center">
                    <div
                      className="flex align-items-center justify-content-center bg-yellow-200 mr-3"
                      style={{
                        width: "7rem",
                        height: "5rem",
                        borderRadius: "50px"
                      }}
                    >
                      <i className="pi pi-fw pi-users text-2xl text-yellow-700"></i>
                    </div>
                    <div>
                      <h5 className="mb-1 text-900 text-lg">Analiz</h5>
                      <span className="text-600 text-sm">
                        Bireysel Sorgu ile belirli bir dosya hakkında hızlı ve etkili bir şekilde detaylı bilgi
                        sorgulaması yapabilirsiniz.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 lg:col-6 p-0 p-1 mt-4 lg:mt-0">
              <div
                className="h-8rem"
                style={{
                  padding: "2px",
                  borderRadius: "10px",
                  background:
                    "linear-gradient(90deg, rgba(253, 228, 165, 0.2), rgba(187, 199, 205, 0.2)), linear-gradient(180deg, rgba(253, 228, 165, 0.2), rgba(187, 199, 205, 0.2))"
                }}
              >
                <div className="p-3 surface-card h-full flex align-items-center" style={{ borderRadius: "8px" }}>
                  <div className="flex align-items-center">
                    <div
                      className="flex align-items-center justify-content-center bg-yellow-200 mr-3"
                      style={{
                        width: "7rem",
                        height: "5rem",
                        borderRadius: "50px"
                      }}
                    >
                      <i className="pi pi-fw pi-users text-2xl text-yellow-700"></i>
                    </div>
                    <div>
                      <h5 className="mb-1 text-900 text-lg">Eğitim</h5>
                      <span className="text-600 text-sm">
                        Bireysel Sorgu ile belirli bir dosya hakkında hızlı ve etkili bir şekilde detaylı bilgi
                        sorgulaması yapabilirsiniz.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}


        <div
          id="features"
          className="py-4 px-4 lg:px-6 border-green-300 rounded-lg shadow-md"
          style={{ backgroundColor: "#114d6b" }}
        >
          <div className="grid gap-4 items-center">

            <div className="flex align-items-center">
              <div className="flex flex-column justify-content-center">
                <h1 className="text-3xl font-bold text-left text-white">YEŞİL DÖNÜŞÜM VE SÜRDÜRÜLEBİLİRLİK MERKEZİ</h1>
                <p className="mt-2 text-left text-white">
                  Organize Sanayi Bölgelerine (OSB) yeşil dönüşümde rehberlik ediyor, sürdürülebilirlik alanında sağlam
                  adımlarla ilerleyen bir topluluk inşa ediyoruz.
                </p>
              </div>
              <img src="images/Resim1.png" className=" w-20rem h-20rem mr-3" />
            </div>
          </div>
        </div>

        <div id="features" className="py-2 px-2 lg:px-4 mt-2 mx-0 lg:mx-4">
          <div className="grid justify-content-center">
            <div className="col-12 lg:col-6">
              <div
        className="card hover-card"
                style={{ height: "8rem", padding: "1rem" }}
                onClick={() => navigate("/app/karbonayakizi", { replace: true })}
              >
                <div className="flex align-items-center">
                  <img src="/images/custom.jpg" className="shadow-2 w-6rem h-6rem mr-3" />
                  <div className="flex flex-column justify-content-center">
                    <h5 className="mb-1 text-900 text-lg">Hesaplama</h5>
                    <span className="text-600 text-sm">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit. Incidunt blanditiis facilis inventore
                      itaque beatae voluptatum dolorem
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 lg:col-6">
              <div
                className="card hover-card"
                style={{ height: "8rem", padding: "1rem" }}
                onClick={() => navigate("/app/karbonayakizi", { replace: true })}
              >
                <div className="flex align-items-center">
                  <img src="/images/custom.jpg" className="shadow-2 w-6rem h-6rem mr-3" />
                  <div className="flex flex-column justify-content-center">
                    <h5 className="mb-1 text-900 text-lg">Raporlama</h5>
                    <span className="text-600 text-sm">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit. Incidunt blanditiis facilis inventore
                      itaque beatae voluptatum dolorem
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 lg:col-6">
              <div
                className="card hover-card"
                style={{ height: "8rem", padding: "1rem" }}
                onClick={() => navigate("/app/karbonayakizi", { replace: true })}
              >
                <div className="flex align-items-center">
                  <img src="/images/custom.jpg" className="shadow-2 w-6rem h-6rem mr-3" />
                  <div className="flex flex-column justify-content-center">
                    <h5 className="mb-1 text-900 text-lg">Analiz</h5>
                    <span className="text-600 text-sm">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit. Incidunt blanditiis facilis inventore
                      itaque beatae voluptatum dolorem
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 lg:col-6">
              <div
                className="card hover-card"
                style={{ height: "8rem", padding: "1rem" }}
                onClick={() => navigate("/app/karbonayakizi", { replace: true })}
              >
                <div className="flex align-items-center">
                  <img src="/images/custom.jpg" className="shadow-2 w-6rem h-6rem mr-3" />
                  <div className="flex flex-column justify-content-center">
                    <h5 className="mb-1 text-900 text-lg">Eğitim</h5>
                    <span className="text-600 text-sm">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit. Incidunt blanditiis facilis inventore
                      itaque beatae voluptatum dolorem
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 lg:col-6">
              <div
                className="card hover-card"
                style={{ height: "8rem", padding: "1rem" }}
                onClick={() => navigate("/app/karbonayakizi", { replace: true })}
              >
                <div className="flex align-items-center">
                  <img src="/images/custom.jpg" className="shadow-2 w-6rem h-6rem mr-3" />
                  <div className="flex flex-column justify-content-center">
                    <h5 className="mb-1 text-900 text-lg">Bilgilendirme</h5>
                    <span className="text-600 text-sm">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit. Incidunt blanditiis facilis inventore
                      itaque beatae voluptatum dolorem
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 lg:col-6">
              <div
                className="card hover-card"
                style={{ height: "8rem", padding: "1rem" }}
                onClick={() => navigate("/app/karbonayakizi", { replace: true })}
              >
                <div className="flex align-items-center">
                  <img src="/images/custom.jpg" className="shadow-2 w-6rem h-6rem mr-3" />
                  <div className="flex flex-column justify-content-center">
                    <h5 className="mb-1 text-900 text-lg">Finansman</h5>
                    <span className="text-600 text-sm">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit. Incidunt blanditiis facilis inventore
                      itaque beatae voluptatum dolorem
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 lg:col-6">
              <div
                className="card hover-card"
                style={{ height: "8rem", padding: "1rem" }}
                onClick={() => navigate("/app/karbonayakizi", { replace: true })}
              >
                <div className="flex align-items-center">
                  <img src="/images/custom.jpg" className="shadow-2 w-6rem h-6rem mr-3" />
                  <div className="flex flex-column justify-content-center">
                    <h5 className="mb-1 text-900 text-lg">Başarı Hikayeleri</h5>
                    <span className="text-600 text-sm">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit. Incidunt blanditiis facilis inventore
                      itaque beatae voluptatum dolorem
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="features" className="py-4 px-4 mt-5 mx-0" style={{ backgroundColor: "#114d6b" }}>
          <Carousel
            value={crouselItems}
            numVisible={5}
            // responsiveOptions={responsiveOptions}
            className=""
            circular
            autoplayInterval={3000}
            itemTemplate={productTemplate}
          />
        </div>

        <div className="py-4 px-4 mx-0 mt-8 lg:mx-8">
          <div className="grid justify-content-between">
            <div className="col-12 md:col-2" style={{ marginTop: "-1.5rem" }}>
              <Link
                to="/"
                className="flex align-items-center justify-content-center md:justify-content-start  cursor-pointer"
              >
                <img
                  src="images/medeasofticon.jpg"
                  alt="footer sections"
                  width="80"
                  height="80"
                  className="mr-2"
                />
                <span className="font-medium text-3xl text-900">MedeaSoft</span>
              </Link>
            </div>

            <div className="col-12 md:col-10 lg:col-7">
              <div className="grid text-center md:text-left">
                <div className="col-12 md:col-3">
                  <h4 className="font-medium text-2xl line-height-3 mb-3 text-900">Company</h4>
                  <a className="line-height-3 text-xl block cursor-pointer mb-2 text-700">About Us</a>
                  <a className="line-height-3 text-xl block cursor-pointer mb-2 text-700">News</a>
                  <a className="line-height-3 text-xl block cursor-pointer mb-2 text-700">Investor Relations</a>
                  <a className="line-height-3 text-xl block cursor-pointer mb-2 text-700">Careers</a>
                  <a className="line-height-3 text-xl block cursor-pointer text-700">Media Kit</a>
                </div>

                <div className="col-12 md:col-3 mt-4 md:mt-0">
                  <h4 className="font-medium text-2xl line-height-3 mb-3 text-900">Resources</h4>
                  <a className="line-height-3 text-xl block cursor-pointer mb-2 text-700">Get Started</a>
                  <a className="line-height-3 text-xl block cursor-pointer mb-2 text-700">Learn</a>
                  <a className="line-height-3 text-xl block cursor-pointer text-700">Case Studies</a>
                </div>

                <div className="col-12 md:col-3 mt-4 md:mt-0">
                  <h4 className="font-medium text-2xl line-height-3 mb-3 text-900">Community</h4>
                  <a className="line-height-3 text-xl block cursor-pointer mb-2 text-700">Discord</a>
                  <a className="line-height-3 text-xl block cursor-pointer mb-2 text-700">
                    Events
                    <img src="/images/new-badge.svg" className="ml-2" alt="badge" />
                  </a>
                  <a className="line-height-3 text-xl block cursor-pointer mb-2 text-700">FAQ</a>
                  <a className="line-height-3 text-xl block cursor-pointer text-700">Blog</a>
                </div>

                <div className="col-12 md:col-3 mt-4 md:mt-0">
                  <h4 className="font-medium text-2xl line-height-3 mb-3 text-900">Legal</h4>
                  <a className="line-height-3 text-xl block cursor-pointer mb-2 text-700">Brand Policy</a>
                  <a className="line-height-3 text-xl block cursor-pointer mb-2 text-700">Privacy Policy</a>
                  <a className="line-height-3 text-xl block cursor-pointer text-700">Terms of Service</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
