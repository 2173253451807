import React, { useContext } from "react";
import AppMenuitem from "./AppMenuitem";
import { LayoutContext } from "./context/layoutcontext";
import { MenuProvider } from "./context/menucontext";
import { AppMenuItem } from "../types/types";

const AppMenu = () => {
  const { layoutConfig } = useContext(LayoutContext);

  const model: AppMenuItem[] = [
    {
      label: "Ana Sayfa",
      items: [{ label: "Ana Sayfa", icon: "pi pi-fw pi-home", to: "/" }]
    },
    {
      label: "Sayfalar",
      icon: "pi pi-fw pi-briefcase",
      to: "/pages",
      items: [
        {
          label: "Hesaplama",
          icon: "pi pi-fw pi-user",
          items: [
            {
              label: "Karbon Ayak İzi",
              icon: "pi pi-fw pi-sign-in",
              to: "/app/karbonayakizi"
            },
            {
              label: "Sera Gazı",
              icon: "pi pi-fw pi-sign-in",
              to: "/login"
            },
            {
              label: "Su Ayak İzi",
              icon: "pi pi-fw pi-lock",
              to: "/access-denied"
            }
          ]
        },
        {
          label: "Raporlama",
          icon: "pi pi-fw pi-user",
          items: [
            {
              label: "Sürdürülebilirlik",
              icon: "pi pi-fw pi-sign-in",
              to: "/login"
            },
            {
              label: "Çevresel Performans ve İyileştirme",
              icon: "pi pi-fw pi-sign-in",
              to: "/login"
            },
            {
              label: "Malzeme ve Kaynak Yönetimi",
              icon: "pi pi-fw pi-lock",
              to: "/access-denied"
            }
          ]
        }
      ]
    },
    {
      label: "Admin Sayfaları",
      items: [
        { label: "Kullanıcılar", icon: "pi pi-fw pi-home", to: "/app/homepage" },
        { label: "Sayfa Güncelleme", icon: "pi pi-fw pi-home", to: "/app/homepage" }
      ]
    }
    // {
    //   label: "Hierarchy",
    //   items: [
    //     {
    //       label: "Submenu 1",
    //       icon: "pi pi-fw pi-bookmark",
    //       items: [
    //         {
    //           label: "Submenu 1.1",
    //           icon: "pi pi-fw pi-bookmark",
    //           items: [
    //             { label: "Submenu 1.1.1", icon: "pi pi-fw pi-bookmark" },
    //             { label: "Submenu 1.1.2", icon: "pi pi-fw pi-bookmark" },
    //             { label: "Submenu 1.1.3", icon: "pi pi-fw pi-bookmark" }
    //           ]
    //         },
    //         {
    //           label: "Submenu 1.2",
    //           icon: "pi pi-fw pi-bookmark",
    //           items: [{ label: "Submenu 1.2.1", icon: "pi pi-fw pi-bookmark" }]
    //         }
    //       ]
    //     },
    //     {
    //       label: "Submenu 2",
    //       icon: "pi pi-fw pi-bookmark",
    //       items: [
    //         {
    //           label: "Submenu 2.1",
    //           icon: "pi pi-fw pi-bookmark",
    //           items: [
    //             { label: "Submenu 2.1.1", icon: "pi pi-fw pi-bookmark" },
    //             { label: "Submenu 2.1.2", icon: "pi pi-fw pi-bookmark" }
    //           ]
    //         },
    //         {
    //           label: "Submenu 2.2",
    //           icon: "pi pi-fw pi-bookmark",
    //           items: [{ label: "Submenu 2.2.1", icon: "pi pi-fw pi-bookmark" }]
    //         }
    //       ]
    //     }
    //   ]
    // }
  ];

  return (
    <MenuProvider>
      <ul className="layout-menu">
        {model.map((item, i) => {
          return !item?.seperator ? (
            <AppMenuitem item={item} root={true} index={i} key={item.label} />
          ) : (
            <li className="menu-separator"></li>
          );
        })}
      </ul>
    </MenuProvider>
  );
};

export default AppMenu;
